import React, {
  createContext, useContext, useEffect, useState,
} from 'react';

const toggleTreshold = 400;

export const NavigationContext = createContext<boolean>(true);

export const NavigationProvider = ({ children } : ComponentProperties) => {
  const [showNavigation, setShowNavigation] = useState(true);
  const [previousScrollY, setPreviousScrollY] = useState(0);

  useEffect(() => {
    const onScroll = () => {
      setPreviousScrollY(window.scrollY);

      if (window.scrollY > toggleTreshold) {
        setShowNavigation(window.scrollY < previousScrollY);
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [previousScrollY, setPreviousScrollY, setShowNavigation]);

  return (
    <NavigationContext.Provider value={showNavigation}>
      {children}
    </NavigationContext.Provider>
  );
};

const useShowNavigation = () => useContext(NavigationContext);

export default useShowNavigation;
