import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  ${({ fullScreen }) => fullScreen && `
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2;
  `}
  ${({ height }) => height && `
    height: ${height};
  `}
`;

const StyledSpinner = styled.svg`
  margin: auto;
  animation: rotate 2s linear infinite;
  width: 50px;
  height: 50px;
  padding: 40px;

  & .path {
    stroke: ${({ stroke }) => stroke};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }

    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const Spinner = ({ stroke }) => (
  <StyledSpinner stroke={stroke} viewBox="0 0 50 50">
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

Spinner.defaultProps = {
  stroke: 'var(--primary-color)',
};

Spinner.propTypes = {
  stroke: PropTypes.string,
};

export default Spinner;
