// eslint-disable-next-line unicorn/prefer-module
module.exports = (proto, host, port) => {
  if (proto === 'http' && port === 80) {
    return host;
  }

  if (proto === 'https' && port === 443) {
    return host;
  }

  if (!port) {
    return host;
  }

  return `${host}:${port}`;
};
