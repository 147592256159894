import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import GlobalStyles from '@mui/material/GlobalStyles';
import {
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import {
  typeFaces,
  fontSizes,
  lineHeights,
  letterSpacing,
} from './constants';
import cssVariables from './cssVariables';
import breakpoints from './breakpoints';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: breakpoints.xs,
      sm: breakpoints.sm,
      md: breakpoints.md,
      lg: breakpoints.lg,
      xl: breakpoints.xl,
    },
  },
  palette: {
    primary: {
      main: cssVariables['--primary-color'],
    },
    secondary: {
      main: cssVariables['--secondary-color'],
    },
    text: {
      primary: cssVariables['--black-high'],
      secondary: cssVariables['--black-medium'],
      disabled: cssVariables['--black-disabled'],
    },
  },
  typography: {
    fontFamily: typeFaces.primary,
    h1: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.headline1,
      lineHeight: lineHeights.headline1,
      letterSpacing: letterSpacing.headline1,
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.headline2,
      lineHeight: lineHeights.headline2,
      letterSpacing: letterSpacing.headline2,
      fontWeight: 'bold',
    },
    h3: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.headline4,
      lineHeight: lineHeights.headline4,
      letterSpacing: letterSpacing.headline4,
      '@media(min-width: 480px)': {
        fontSize: fontSizes.headline3,
        lineHeight: lineHeights.headline3,
        letterSpacing: letterSpacing.headline3,
      },
      fontWeight: 'bold',
    },
    h4: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.headline4,
      lineHeight: lineHeights.headline4,
      letterSpacing: letterSpacing.headline4,
      fontWeight: 'bold',
    },
    h5: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.headline5,
      lineHeight: lineHeights.headline5,
      letterSpacing: letterSpacing.headline5,
      fontWeight: 'bold',
    },
    h6: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-inactive'],
      fontSize: fontSizes.headline6,
      lineHeight: lineHeights.headline6,
      letterSpacing: letterSpacing.headline6,
      fontWeight: 'bold',
    },
    subtitle1: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.subtitle1,
      lineHeight: lineHeights.subtitle1,
      letterSpacing: letterSpacing.subtitle1,
      fontWeight: 'bold',
    },
    subtitle2: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-inactive'],
      fontSize: fontSizes.subtitle2,
      lineHeight: lineHeights.subtitle2,
      letterSpacing: letterSpacing.subtitle2,
      fontWeight: 'bold',
    },
    body1: {
      fontFamily: typeFaces.primary,
      color: cssVariables['--black-high'],
      fontSize: fontSizes.body1,
      lineHeight: lineHeights.body1,
      letterSpacing: letterSpacing.body1,
    },
    body2: {
      fontFamily: typeFaces.primary,
      color: cssVariables['--black-medium'],
      fontSize: fontSizes.body2,
      lineHeight: lineHeights.body2,
      letterSpacing: letterSpacing.body2,
    },
    button: {
      fontFamily: typeFaces.secondary,
      fontSize: fontSizes.button,
      lineHeight: lineHeights.button,
      letterSpacing: letterSpacing.button,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    caption: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--black-inactive'],
      fontSize: fontSizes.caption,
      lineHeight: lineHeights.caption,
      letterSpacing: letterSpacing.caption,
      fontWeight: 'bold',
    },
    overline: {
      fontFamily: typeFaces.secondary,
      color: cssVariables['--primary-color'],
      fontSize: fontSizes.overline,
      lineHeight: lineHeights.overline,
      letterSpacing: letterSpacing.overline,
      fontWeight: 'bold',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '24px',
          paddingBottom: '24px',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          listStyleType: 'none',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          color: cssVariables['--black-medium'],
          maxWidth: '600px',
          flexWrap: 'nowrap',
          borderRadius: 0,
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          maxWidth: '800px',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontFamily: typeFaces.primary,
          margin: '4px',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            minWidth: '0px',
            fontSize: '1.125rem',
            padding: '0px 16px',
            marginTop: '4px',
          },
        },
        textColorInherit: {
          color: cssVariables['--black-high'],
          opacity: '1',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: typeFaces.secondary,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: cssVariables['--black-medium'],
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          fontFamily: typeFaces.secondary,
          color: cssVariables['--black-medium'],
        },
      },
    },
  },
});

const hyphenation = {
  hyphens: 'auto',
  hyphenateLimitChars: '14 5 5',
} as const;

const globalStyles = (
  <GlobalStyles styles={{
    ':root': {
      ...cssVariables,
      ...hyphenation,
      overflowY: 'scroll',
    },
  }}
  />
);

const themePropertyTypes = {
  children: PropTypes.node,
};

type ThemeProperties = InferProps<typeof themePropertyTypes>;

export default function ThemeWrapper({ children }: ThemeProperties) {
  return (
    <ThemeProvider theme={theme}>
      {globalStyles}
      {children}
    </ThemeProvider>
  );
}

ThemeWrapper.propTypes = themePropertyTypes;
