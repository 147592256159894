import React from 'react';
import styled from '@emotion/styled';

const HiddenLoader = styled(() => (
  <span className="hidden-loader" />
))`
height: 0;
`;

export default HiddenLoader;
