const typeFaces = {
  // eslint-disable-next-line max-len
  primary: 'Helvetica Neue, Helvetica, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
  // eslint-disable-next-line max-len
  secondary: 'Helvetica Neue, Helvetica, -apple-system, BlinkMacSystemFont, Roboto, Arial, sans-serif',
  // eslint-disable-next-line max-len
  article: 'Georgia, Cambria, "Bitstream Charter", "Charis SIL", Utopia, "URW Bookman L", "Times New Roman", Times, serif',
};

const fontSizesRelative = {
  headline1: 48,
  headline2: 36,
  headline3: 30,
  headline4: 24,
  headline5: 20,
  headline6: 18,
  subtitle1: 14,
  subtitle2: 12,
  body1: 16,
  body2: 15,
  button: 14,
  caption: 12,
  overline: 10,
};

const lineHeightsRelative = {
  headline1: 48,
  headline2: 40,
  headline3: 36,
  headline4: 32,
  headline5: 28,
  headline6: 28,
  subtitle1: 24,
  subtitle2: 20,
  body1: 24,
  body2: 20,
  button: 20,
  caption: 16,
  overline: 14,
};

const letterTracking = {
  headline1: -5,
  headline2: -3.5,
  headline3: -0.9,
  headline4: -1,
  headline5: 0,
  headline6: 0,
  subtitle1: 0.15,
  subtitle2: 0.1,
  body1: 0,
  body2: 0,
  button: 0,
  caption: 0.4,
  overline: 1.5,
};

const fontSizeConverter = (factor) => {
  const conversionRatio = 0.0625;
  return `${factor * conversionRatio}rem`;
};

const letterSpacingConverter = (tracking, fontSize) => (
  `${tracking / fontSize}rem`
);

const fontSizes = Object.fromEntries(
  Object.entries(fontSizesRelative).map(
    ([key, value]) => [key, fontSizeConverter(value)],
  ),
);

const lineHeights = Object.fromEntries(
  Object.entries(lineHeightsRelative).map(
    ([key, value]) => [key, fontSizeConverter(value)],
  ),
);
const letterSpacing = Object.fromEntries(
  Object.entries(fontSizesRelative).map(
    ([key, value]) => ([
      key,
      letterSpacingConverter(letterTracking[key], value),
    ]),
  ),
);

export {
  typeFaces,
  fontSizes,
  lineHeights,
  letterSpacing,
};
