import React from 'react';
import PropTypes from 'prop-types';
import Spinner, { SpinnerContainer }
from '@fuww/library/src/Spinner';

const Loader = ({ fullScreen, height }) => (
  <div>
    <SpinnerContainer
      fullScreen={fullScreen}
      height={height}
    >
      <Spinner />
    </SpinnerContainer>
  </div>
);

Loader.propTypes = {
  fullScreen: PropTypes.bool,
  height: PropTypes.string,
};

Loader.defaultProps = {
  fullScreen: false,
  height: undefined,
};

export default Loader;
