import { typeFaces, fontSizes } from './constants';

const typography = {
  '--font-primary': typeFaces.primary,
  '--font-secondary': typeFaces.secondary,
  '--font-article': typeFaces.article,
  '--size-h1': fontSizes.headline1,
  '--size-h2': fontSizes.headline2,
  '--size-h3': fontSizes.headline3,
  '--size-h4': fontSizes.headline4,
  '--size-h5': fontSizes.headline5,
  '--size-h6': fontSizes.headline6,
  '--size-body1': fontSizes.body1,
  '--size-caption': fontSizes.caption,
  '--size-body': fontSizes.body2,
  '--size-btn': fontSizes.button,
  '--size-sub1': fontSizes.subtitle1,
  '--size-sub2': fontSizes.subtitle2,
  '--size-overline': fontSizes.overline,
};

const palette = {
  '--black-high': 'rgba(0, 0, 0, .87)',
  '--black-medium': 'rgba(0, 0, 0, .60)',
  '--black-inactive': 'rgba(0, 0, 0, 0.54)',
  '--black-disabled': 'rgba(0, 0, 0, .38)',
  '--black': 'rgb(0, 0, 0)',
  '--primary-color': 'rgb(234, 0, 81)',
  '--secondary-color': '#f50057',
  '--primary-text': 'rgb(219, 0, 77)',
  '--grey-dark': 'rgb(88, 89, 91)',
  '--grey-light': 'rgb(219, 219, 219)',
  '--white-light': 'rgba(255, 255, 255, .70)',
  '--white': 'rgb(255, 255, 255)',
  '--blue': 'rgb(25, 118, 210)',
};

const cssVariables = { ...palette, ...typography };

export default cssVariables;
